import React from 'react';
import { graphql } from 'gatsby';
import useDefaultTheme from './../hooks/useDefaultTheme';
import Seo from '../components/Seo';
import Blocks from '../components/Blocks';

const PageTemplate = ({ data }) => {
	const page = data && data.page;
	useDefaultTheme(page.theme);
	return (
		<>
			<Seo
				metaTitle={page.seo?.metaTitle || page.title}
				shareTitle={page.seo?.shareTitle || page.title}
				metaDesc={page.seo?.metaDesc}
				shareDesc={page.seo?.shareDesc}
				shareGraphic={page.seo?.shareGraphic?.asset.url}
			/>
			<h1 className="hidden">{page.title}</h1>
			<main className="space-y-4vw pb-4vw">
				<Blocks blocks={page.blocks} _rawBlocks={page._rawBlocks} />
			</main>
		</>
	);
};

export default PageTemplate;

export const query = graphql`
	query pageQuery($_id: String!) {
		page: sanityPage(_id: { eq: $_id }) {
			id
			title
			theme
			seo {
				...seoFields
			}
			_rawBlocks(resolveReferences: { maxDepth: 10 })
			blocks {
				...blocks
			}
		}
	}
`;
